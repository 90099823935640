<template>
   <div id="inside">
     
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center text-secondary"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <router-link
              to="/dashboard/negociarDivida"
              ><a class="btn btn-link text-secondary">Negociar Dívidas</a>
            </router-link>
            <router-link
              to="/dashboard/negociar"
              ><a href="#" class="btn btn-link text-secondary" style="text-decoration: none; cursor: initial;">Negociar</a>
            </router-link>
             <router-link
              to="/dashboard/resumoAcordo"
              ><a href="#" class="btn btn-link text-secondary" style="text-decoration: none; cursor: initial;">Resumo do Acordo</a>
            </router-link>
        </div>
        <div class="col-md-12">
            <h1>Resumo do Acordo</h1>
            <p class="text-muted">Veja com detalhes o acordo que será gerado</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">        
        <div class="d-inline-flex flex-wrap flex-column col-acao col-lg-12">
          <div class="card" v-if="!loading">
              <div class="card-header"><i class="fa fa-fw mr-2 fa-circle text-warning"></i> Resumo </div>
              <div class="card-body mb-0">
                <div class="row mb-0">
                  <div class="col-md-12 mb-0">
                    <div class="row mb-0">                         
                        <div class="col-md-12 col-12">
                          <p class="text-info m-0">CPF {{ formatDocument(user.DocNumber) }} - Data: {{ formatData(new Date(),"YYYY-MM-DD","L")}} </p>                          
                        </div>
                    </div>                  
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-sm" width="100%" cellspacing>
                    <thead class="">
                      <tr class="small">
                        <th>Contrato</th>
                        <th>Produto</th>
                        <th>V. Atualizado</th>
                        <th>V. Acordado</th>
                        <th>% Desconto</th>
                        <th width="25%">Parcela(s)</th>
                      </tr>
                    </thead>
                    <tbody class="text-muted" v-if="contracts != null">
                      <tr v-for="(value, key, index) in contracts"
                        :key="`${key}-${index}`">
                        <td style="font-size:14px">{{ value.CONTRATO }} </td>
                        <td style="font-size:14px">{{ value.PRODUTO }} </td>
                        <td>R$ {{ value.VALPRINC }}</td>
                        <td>R$ {{ value.VALPRINC }}</td>
                        <td>0%</td>
                        <td style="font-size:11px">{{ value.NUMPREST }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>               

                <div class="row mb-0">
                  
                    <div class="col-md-12 mb-0">
                      <!-- <div class="row mb-0">                         
                          <div class="col-md-4 col-4">
                            <p class="m-0 text-info">Vencimento:</p>
                            <h6 v-if="agreementResume.Resumo.TipoAcordo == 1">{{ formatData(agreementResume.Resumo.DataPagamentoSimulacao,"YYYY-MM-DD","L")}}</h6>
                            <h6 v-if="agreementResume.Resumo.TipoAcordo == 2">{{ formatData(agreementResume.Resumo.DataEntrada != null ? agreementResume.Resumo.DataEntrada : agreementResume.Resumo.DataVencimentoPrimeiraParcela, "YYYY-MM-DD", "L")}}</h6>
                          </div>                          
                          <div class="col-md-4 col-4">
                            <p class="m-0 text-info">Valor Total Atualizado:</p>
                            <h6>R$ {{ formatValue(agreementResume.Resumo.ValorDividaOriginal) }}</h6>
                          </div>
                          <div class="col-md-4 col-4">
                            <p class="m-0 text-info">Valor Total Acordado:</p>
                            <h6>R$ {{ formatValue(agreementResume.Resumo.TipoAcordo == 1?agreementResume.Resumo.ValorTotalPagarSimulacao:agreementResume.Resumo.ValorTotalPagarDesconto) }}</h6>                             
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Descrição:</p>
                            <h6>{{ agreementResume.FormaPagamento.Descricao}}</h6>
                          </div>
                          <div class="col-md-4 col-4">
                            <p class="text-info mb-0">Forma de Pagamento:</p>
                            <p  v-if="agreementResume.Resumo.TipoAcordo == 1">{{ agreementResume.Resumo.MeioPagamentoTitulo}}</p>
                            <p  v-if="agreementResume.Resumo.TipoAcordo == 2">{{ agreementResume.Resumo.MeioPagamentoPrimeiraParcela == "B" ? 'Boleto' : agreementResume.Resumo.MeioPagamentoPrimeiraParcela }}</p>
                          </div>
                          <div class="col-md-4 col-4">
                            <p class="text-info mb-0">Percentual Total de Desconto:</p>
                            <p>{{agreementResume.Resumo.PercDescontoTotalSimulacao}}%</p>
                          </div>

                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="text-info mb-0">Forma de Pagamento Demais Parcelas:</p>
                            <h6>{{ agreementResume.Resumo.MeioPagamentoPrimeiraParcela == "B" ? 'Boleto' : agreementResume.Resumo.MeioPagamentoPrimeiraParcela }}</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Vecimento Primeira Parcela:</p>
                            <h6>{{ formatData(agreementResume.Resumo.DataVencimentoPrimeiraParcela,"YYYY-MM-DD","L") }}</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Vecimento Última Parcela:</p>
                            <h6>{{ formatData(agreementResume.Resumo.DataVencimentoUltimaParcela,"YYYY-MM-DD","L") }}</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Dia Vencimento Demais Parcelas:</p>
                            <h6>{{ agreementResume.Resumo.DiaVencimentoDemaisParcela}}</h6>
                          </div>

                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Juros a.m.:</p>
                            <h6>{{ formatValue(agreementResume.Resumo.TaxaJurosMensal)}}%</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Juros a.a.:</p>
                            <h6>{{ formatValue(agreementResume.Resumo.TaxaJurosAnual)}}%</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">C.E.T a.m.:</p>
                            <h6>{{ formatValue(agreementResume.Resumo.Cet.TaxaJurosMensalCet)}}%</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">C.E.T a.a.:</p>
                            <h6>{{ formatValue(agreementResume.Resumo.Cet.TaxaJurosAnualCet)}}%</h6>
                          </div>
                          <div class="col-md-4 col-4" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">Desconto Total:</p>
                            <h6>R$ {{ formatValue(agreementResume.Resumo.ValorTotalDescontoSimulacao)}}</h6>
                          </div>
                          <div class="col-md-12 col-12" v-if="agreementResume.Resumo.TipoAcordo ==2">
                            <p class="m-0 text-info">IOF:</p>
                            <h6>*"{{ agreementResume.Resumo.Cet.DescricaoIof}}"</h6>
                          </div>
                      </div> -->
                      <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                      <hr>
                      <a class="btn mr-1 mt-3 mb-0 btn-outline-primary btn-block" @click="acordoFechado()" v-if="!loadingAgreement">
                      <i class="fa fa-fw fa-check"></i>&nbsp;CONCORDO</a>

                      <a class="btn mr-1 mt-3 mb-0 btn-outline-primary btn-block" href="#" v-if="loadingAgreement">
                      <ProgressLoading v-bind:loading="true" /></a>
                    
                    </div>
                </div>
              </div>
          </div>

          <div class="card" v-if="loading">
              <ProgressLoading v-bind:loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData,formatDocument } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";

export default {
    name: "HistoricoPagamentos",
     components: {
       ProgressLoading
    },
    data () {
        return {
          containParcel:false,
          loading:true,
          loadingAgreement:false,
          parcelado:false
        }
    },
    async created() {
        this.contracts = this.$route.params.contracts;  
        // await this.ActionContractsAgreementResume(this.getAgreementPayload());
        // await this.containParcelas();
        this.loading = false;
    },
    methods: {
        formatValue,
        formatData,
        formatDocument,

        ...mapActions('minhasDividas', ['ActionDividaNegociar','ActionContractsAgreementResume','ActionContractsAgreement']),

        // getAgreementPayload(){
        //    let payloadContracts = [];
        //     let contractscts = this.contractNegotiable.filter((f)=> f.selected).map((m)=> m.NumeroContrato);
            
        //     contractscts.forEach((item)=>{
        //         this.contractNegotiableOptions['Contratos'].forEach((a)=>{
        //           if(a.NumeroContrato == item)
        //               payloadContracts.push(a);
        //         });
        //     });

        //    return {
        //       "Contratos": payloadContracts,
        //       "FormaPagamento": this.$route.params.formaPagamento,
        //       "TipoNegociacao": this.contractNegotiable.filter((f)=> f.selected)[0].TipoNegociacao,
        //     };
        // },

        // async acordoFechado() {
        //     this.loadingAgreement = true;
        //     let payload = this.getAgreementPayload();

        //     var result = await this.ActionContractsAgreement({
        //       "IdSimulacao": this.agreementResume.Resumo.IdSimulacao,
        //       "IdResumo": this.agreementResume.Resumo.IdResumo,
        //       "IdJornada": this.agreementResume.Resumo.IdJornada,
        //       "TipoAcordo": this.agreementResume.Resumo.TipoAcordo,
        //       "Contratos": payload['Contratos'],
        //       "FormaPagamento":payload['FormaPagamento'],
        //       "TipoNegociacao": this.contractNegotiable.filter((f)=> f.selected)[0].TipoNegociacao,
        //     });
        //     this.loadingAgreement = false;

        //     this.$router.push({ 
        //       name: 'meusBoletos',
        //       params: { agreement:  result },
        //     });
        // },

        async acordoFechado() {
            this.loadingAgreement = true;
            
            this.loadingAgreement = false;
            
            this.$router.push({ 
              name: 'meusBoletos',
              params: { agreement:  this.contracts },
            });
        },

        containParcelas(){
           var array = this.contractNegotiable.filter((f)=> f.selected && f.Parcelas.filter((filter) => filter.selected == true).length > 0);
            this.containParcel = array.length > 0;
        },

        getParcelas(idContrato){
           let parcelas = "";
            this.contractNegotiable.filter((f)=> f.selected).forEach((ctr) => {
              if (ctr.NumeroContrato == idContrato)
                parcelas = ctr.Parcelas.filter((filter) => filter.selected == true).map((i)=> i.NumeroParcela).join(', ');              
            });
              
            return parcelas;
        }

    },
    computed: {
      ...mapState("auth", ["user"]),
      ...mapState("minhasDividas", ["agreementResume","contractNegotiable","contractNegotiableOptions"]),     
    }
};
</script>
